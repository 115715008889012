// ** Initial State
const initialState = {
  objectDefinitions: [],
  records: [],
  permSet : {},
  total: 1,
  params: {}
}

const acls = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OBJECT_DEFINITIONS':
      return {
              ...state,
              objectDefinitions: action.data,
              total: action.totalPages,
              params: action.params
            }
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_RECORDS_PAGE':
      return {
      ...state,
      records: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_ACL_RECORDS':
    return {
      ...state,
      permSet: action.data,
      total: action.totalPages,
      params: action.params
    }
    case 'GET_PICKLIST':
      return { ...state, selectedRow: action.selectedRow }
    case 'ADD_PICKLIST':
      return { ...state }
    case 'DELETE_PICKLIST':
      return { ...state }
    case 'SET_SELECTED_MICROAPI':
      return {...state, selectedMicroAPi:action.data}  
    default:
      return { ...state }
  }
}
export default acls
