// ** Initial State
const initialState = {
  allData: [],
  exportImportsArr: [],
  total: 1,
  params: {},
  selectedexportImport: null
}

const exportImportslist = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_exportImports':
      return {
        ...state,
        exportImportsArr: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_exportImport':
      return { ...state, selectedexportImport: action.selectedexportImport }
    case 'ADD_exportImport':
      return { ...state }
    case 'DELETE_exportImport':
      return { ...state }
    default:
      return { ...state }
  }
}
export default exportImportslist
