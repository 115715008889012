// ** Initial State
const initialState = {
  records:[],
  recordsMap:{},
  totalRecords:0,
  allData: [],
  pages: [],
  total: 1,
  params: {},
  block: true,
  newRecordAdded:'',
  recordActions:'',
}

const pagesList = (state = initialState, action) => {
  switch (action.type) {
    case 'RECORDS_BLOCK_PAGE':
      return {block: action.block}
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'RECORDS_FOR_PAGE':
      if(action && action.approvalStatus && action.approvalStatus.trim().length>0){
        if(state.recordsMap){

        } else {
          state.recordsMap = {};
        }
        state.recordsMap[action.approvalStatus] = action.data;
        return {
          ...state,
          records:[],
          recordsMap: state.recordsMap,
          totalRecords: action.totalElements,
          total: action.totalPages,
          params: action.params,
          block: false
        }
      } else {
        return {
          ...state,
          records: action.data,
          recordsMap: {},
          totalRecords: action.totalElements,
          total: action.totalPages,
          params: action.params,
          block: false
        }
      }      
    case 'GET_RECORD_DATA':
      return {
        ...state,
        pages: action.data,
        total: action.totalPages,
        params: action.params,
        block: false
      }
    case 'GET_PAGE':
      return { ...state, selectedRow: action.selectedRow }
    case 'ADD_PAGE':
      return { ...state }
    case 'NEW_RECORD_ADDED':
      return {...state,
        newRecordAdded: action.newRecordAdded }
    case 'RECORD_ACTIONS':
      return {...state,
        recordActions: action.recordActions }
    case 'DELETE_PAGE':
      return { ...state }
    default:
      return { ...state }
  }
}
export default pagesList
