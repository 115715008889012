// ** Initial State
const initialState = {
  allData: [],
  dynamicSchedulersArr: [],
  total: 1,
  params: {},
  selectedDynamicScheduler: null
}

const dynamicSchedulerslist = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DynamicSchedulers':
      return {
        ...state,
        dynamicSchedulersArr: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DynamicScheduler':
      return { ...state, selectedDynamicScheduler: action.selectedDynamicScheduler }
    case 'ADD_DynamicScheduler':
      return { ...state }
    case 'DELETE_DynamicScheduler':
      return { ...state }
    default:
      return { ...state }
  }
}
export default dynamicSchedulerslist
