// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/collaborations/chat/store/reducer'
import todo from '@src/views/collaborations/todo/store/reducer'
import users from '@src/views/administration/user/store/reducer'
import email from '@src/views/collaborations/email/store/reducer'
import invoice from '@src/views/ecommerce/invoice/store/reducer'
import calendar from '@src/views/collaborations/calendar/store/reducer'
import ecommerce from '@src/views/ecommerce/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import objectrecords from '@src/views/administration/objectrecord/store/reducer'
import records from '@src/views/record/store/reducer'
import objectlist from '@src/views/administration/object/store/reducer'
import picklists from '@src/views/administration/picklist/store/reducer'
import roleslist from '@src/views/administration/role/store/reducer'
import appslist from '@src/views/administration/apps/store/reducer'
import acls from '@src/views/administration/acl/store/reducer'
import pagesList from '@src/views/page/store/reducer'
import microAPIsList from '@src/views/administration/microapi/store/reducer'
import workflowsList from '@src/views/administration/workflow/store/reducer'
import ocrTemplatesList from '@src/views/administration/ocrTemplate/store/reducer'
import ocrFormsList from '@src/views/administration/ocrForm/store/reducer'
import taskQueuesList from '@src/views/administration/taskQueue/store/reducer'
import settingsList from '@src/views/administration/settings/store/reducer'
import dynamicSchedulerslist from '@src/views/administration/dynamicScheduler/store/reducer'
import teamsList from '@src/views/team/store/reducer'
import filesList from '../../views/fileManager/store/reducer'
import dashboard from '@src/views/dashboard/manage/store/reducer'
import exportImportslist from '@src/views/administration/exportImport/store/reducer'
import advancedFormslist from '@src/views/administration/advancedForm/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  objectrecords,
  records,
  objectlist,
  picklists,
  roleslist,
  appslist,
  acls,
  pagesList,
  microAPIsList,
  workflowsList,
  settingsList,
  exportImportslist,
  ocrTemplatesList,
  ocrFormsList,
  taskQueuesList,
  dynamicSchedulerslist,
  advancedFormslist,
  teamsList,
  filesList,
  dashboard
})

export default rootReducer
