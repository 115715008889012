// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: 'Ara',
    //appLogoImage: require('@src/assets/images/logo/ara-logo.png').default,
    //appLogoImage: require('@src/assets/images/logo/logo_ara_final1.png').default,
    //appLogoImage: require('@src/assets/images/logo/logo_ara_final.png').default,
    appLogoImage: require('@src/assets/images/logo/120px_logo_ara.png').default,
    uaePassLogo: require('@src/assets/images/logo/uae-pass.png').default,
    uaePassLoginEnabled:true,
    googleLoginEnabled:false,
    fbLoginEnabled:false,
    microsoftLoginEnabled:true,
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'fadeIn', // fadeIn, fadeInLeft, zoomIn, none or check this for more transition https://animate.style/
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'static', // static , sticky , floating, hidden
      backgroundColor: 'white' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true // Enable scroll to top button
  }
}

export default themeConfig
