import mock from './mock'
import './tables/datatables'
import './autoComplete/autoComplete'
import './navbar/navbarSearch'
import './collaborations/email'
import './collaborations/chat'
import './collaborations/todo'
import './collaborations/calendar'
import './collaborations/userList'
import './collaborations/invoice'
import './collaborations/eCommerce'
import './pages/my-profile'
import './pages/profile'
import './pages/faq'
import './pages/knowledge-base'
import './pages/pricing-data'
import './pages/blog-data'
import './cards/card-analytics'
import './cards/card-statistics'
import './jwt'
import './pages/marketPlaceData'
import './pages/marketPlaceInstalledData'

mock.onAny().passThrough()
