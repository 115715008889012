// ** Initial State
const initialState = {
  objectDefinitions: [],
  records: [],
  total: 1,
  params: {}
}

const objectrecords = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OBJECT_DEFINITIONS':
      return {
              ...state,
              objectDefinitions: action.data,
              total: action.totalPages,
              params: action.params
            }
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_RECORDS_PAGE':
      return {
        ...state,
        records: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_PICKLIST':
      return { ...state, selectedRow: action.selectedRow }
    case 'ADD_PICKLIST':
      return { ...state }
    case 'DELETE_PICKLIST':
      return { ...state }
    default:
      return { ...state }
  }
}
export default objectrecords
