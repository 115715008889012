const initialState = {
    allData: [],
    data: [],
    total: 1,
}


const filesList = (state = initialState, action) => {
    switch (action.type) {
        
        case 'GET_FILES':
            return {
                ...state,
                data: action.data,
                total: action.totalPages
            }
        case 'GET_FILENAME':
            return { 
                ...state,
                data: action.data,
                total: action.totalPages
            }
        default:
            return { ...state }
    }
}
export default filesList