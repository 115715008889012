// ** Initial State
const initialState = {
  allData: [],
  teamsArr: [],
  total: 1,
  params: {},
  selectedTeam: null
}

const teamsList = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_TEAMS':
      return {
        ...state,
        teamsArr: action.data,
        total: action.totalPages,
        params: action.params
      }
      case 'GET_MYTEAMS':
      return {
        ...state,
        teamsArr: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_Team':
      return { ...state, selectedTeam: action.selectedTeam }
    case 'ADD_Team':
      return { ...state }
    case 'DELETE_TEAM':
      return { ...state }
    case 'DELETE_TEAMALL':
      return { ...state }
    default:
      return { ...state }
  }
}
export default teamsList
