import mock from '../mock'
const data = {
  marketPlaceData: {
    // payment
    workflow: {
      icon: 'CreditCard',
      title: 'Workflow',
      icon: 'ShoppingBag',
      publisher: 'Ara SAAS Technology',
      subtitle: 'Which license do I need?',
      qandA: [
        {
          title: 'Workflow Management, Approvals, UAEPass document signing?',
          icon: 'ShoppingBag',
          publisher: 'Ara SAAS Technology',
          documentURL: 'https://docs.aracloud.in/',
          subtitle:
            '1Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
        }
      ]
    },
    // delivery
    calendar: {
      title: 'Booking Schedulers',
      icon: 'Calendar',
      publisher: 'Ara SAAS Technology',
      subtitle: 'Calendar Booking & Schedulers',
      qandA: [
        {
          title: 'Calendar Booking & Schedulers',
          icon: 'Calendar',
          publisher: 'Ara SAAS Technology',
          subtitle:
            '2Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
        }
      ]
    },
    // cancellation and return
    contentManagement: {
      icon: 'Image',
      title: 'Content Management',
      publisher: 'Ara SAAS Technology',
      subtitle: 'Blogs, Web Pages, e-Books, Other Digital Publishing',
      qandA: [
        {
          title: 'Blogs, Web Pages, e-Books, Other Digital Publishing',
          icon: 'Image',
          publisher: 'Ara SAAS Technology',
          subtitle:
            'Pastry pudding cookie toffee bonbon jujubes jujubes powder topping. Jelly beans gummi bears sweet roll bonbon muffin liquorice. Wafer lollipop sesame snaps. Brownie macaroon cookie muffin cupcake candy caramels tiramisu. Oat cake chocolate cake sweet jelly-o brownie biscuit marzipan. Jujubes donut marzipan chocolate bar. Jujubes sugar plum jelly beans tiramisu icing cheesecake.'
        }
      ]
    
    }
  }
}

mock.onGet('/marketPlace/data').reply(config => {
  const { q = '' } = config.params
  const queryLowered = q.toLowerCase()

  const filteredData = {}

  Object.entries(data.marketPlaceData).forEach(entry => {
    const [categoryName, categoryObj] = entry
    const filteredQAndAOfCategory = categoryObj.qandA.filter(qAndAObj => {
      return qAndAObj.title.toLowerCase().includes(queryLowered)
    })
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = { ...categoryObj, qandA: filteredQAndAOfCategory }
    }
  })

  return [200, filteredData]
})
